import React, { useState } from "react";
import { useAppDispatch } from "../hooks/redux-hooks"
import { addUser } from "../reducers/users";
import { useNavigate } from "react-router-dom";

const CreateUser = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [showError, setShowError] = useState(false);
    const handleCreateClick = async () => { 
        await dispatch(addUser({username, password})).unwrap()
        .then(() => navigate('/admin'))
        .catch(() => setShowError(true))
    }
    const handleEnterPress = (event:any)=>{if (event.key === 'Enter') {
        handleCreateClick()
    }}

    return (
        <div>
            <div className="login">
                <h2>Add User</h2>
                <div className="login-item">
                    <label htmlFor="username">Username: </label>
                    <input id="username" type="text" onChange={(event)=>setUsername(event.target.value)} onKeyUp={handleEnterPress}/>
                </div>
                <div className="login-item">
                    <label htmlFor="password">Password: </label>
                    <input id="password" type="password" onChange={(event)=>setPassword(event.target.value)} onKeyUp={handleEnterPress}/>       
                </div>
                {showError && <div className="error">
                    <span>Failed to add user</span>
                </div>}
                <div className="login-item">
                    <button onClick={handleCreateClick}>Create</button>
                </div>
            </div>
        </div>
    )
}

export default CreateUser