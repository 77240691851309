import { configureStore } from '@reduxjs/toolkit'
import parksReducer from './reducers/parks';
import authReducer from './reducers/auth';
import usersReducer from './reducers/users';
import filesReducer from './reducers/files';

const store = configureStore({
  reducer: {
    parks: parksReducer,
    auth: authReducer,
    users: usersReducer,
    files: filesReducer,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store;