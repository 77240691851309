import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from "../api/axiosInstance";

interface FilesState {
  file: File | null,
  status: "idle" | "uploading" | "failed" | "uploaded";
  error: string | null;
}

const initialState: FilesState = {
    file: null,
    status: "idle",
    error: null
}

export const uploadBudgetFile = createAsyncThunk("uploadBudgetFile", async (file: File | null) => {    
    if(!file) {
        return null
    }
    const formData = new FormData();
    formData.append('file', file);
    const response = await axiosInstance.post("/budget", formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
    })
    const resData = response.data;

    return resData;
});

export const filesSlice = createSlice({
  name: 'files',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(uploadBudgetFile.pending, (state) => {
        state.status = "uploading";
        state.error = null;
      })
      .addCase(
        uploadBudgetFile.fulfilled,
        (state) => {
          state.status = "uploaded";
        }
      )
      .addCase(uploadBudgetFile.rejected, (state) => {
        state.status = "failed";
        state.error = "Failed to upload file";
      })
    }
})

export default filesSlice.reducer
