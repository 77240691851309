import React from 'react';
import './App.css';
import Parks from './app/components/parks';
import { Route, Routes } from 'react-router-dom';
import DefaultLayout from './app/components/defaultLayout';
import ProtectedLayout from './app/components/protectedLayout';
import Login from './app/components/login';
import Admin from './app/components/admin';
import CreateUser from './app/components/createUser';

function App() {
  return (
    <Routes>
      <Route element={<DefaultLayout />}>
        <Route path="/login" element={<Login />} />
      </Route>
      <Route element={<ProtectedLayout />}>
        <Route path="/" element={<Parks />} />
        <Route path="/admin" element={<Admin/>} />
        <Route path="/admin/addUser" element={<CreateUser/>} />
      </Route>
    </Routes>
  );
}

export default App;
