import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../hooks/redux-hooks"
import { login } from "../reducers/auth";
import Header from "./header";
import { RootState } from "../store";

const Login = () => {
    const dispatch = useAppDispatch();
    const authError = useAppSelector((state: RootState) => state.auth.error)
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const handleLoginClick = async () => dispatch(login({username, password}))
    const handleEnterPress = (event:any)=>{if (event.key === 'Enter') {
        handleLoginClick()
    }}

    return (
        <div>
            <Header />
            <div className="login">
                <div className="login-items">
                    <div className="login-item">
                        <label htmlFor="username">Username: </label>
                        <input id="username" type="text" onChange={(event)=>setUsername(event.target.value)} onKeyUp={handleEnterPress}/>
                    </div>
                    <div className="login-item">
                        <label htmlFor="password">Password: </label>
                        <input id="password" type="password" onChange={(event)=>setPassword(event.target.value)} onKeyUp={handleEnterPress}/>       
                    </div>
                    {authError && <div className="error">
                        <span>{authError}</span>
                    </div>}
                    <div className="login-item">
                        <button onClick={handleLoginClick}>Login</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login