import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axiosInstance from "../api/axiosInstance";

interface UserInfo {
  name: string,
  is_admin: boolean,
}

export type UserLogin = {
  username: string;
  password: string;
};

interface AuthState {
  info: UserInfo | null,
  status: "idle" | "loading" | "failed";
  error: string | null;
}

const initialState: AuthState = {
    info: localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo") as string)
    : null,
    status: "idle",
    error: null
}

export const login = createAsyncThunk("login", async (data: UserLogin) => {
    const response = await axiosInstance.post("/login", data);        
    const resData = response.data;    
    
    localStorage.setItem("userInfo", JSON.stringify(resData));
  
    return resData;
  });

export const logout = createAsyncThunk("logout", async () => {
    const response = await axiosInstance.post("/logout");
    const resData = response.data;

    localStorage.removeItem("userInfo");

    return resData;
});

export const authSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(
        login.fulfilled,
        (state, action: PayloadAction<UserInfo>) => {
          state.status = "idle";
          state.info = action.payload;
        }
      )
      .addCase(login.rejected, (state, action) => {
        state.status = "failed";
        state.info = null;
        state.error = "Wrong username or password";
      })
      .addCase(logout.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(
        logout.fulfilled,
        (state) => {
          state.status = "idle";
          state.info = null;
        }
      )
      .addCase(logout.rejected, (state, action) => {
        localStorage.removeItem("userInfo");
        state.status = "failed";
        state.info = null;
        state.error = "Logout failed";
      })
    }
})

export default authSlice.reducer