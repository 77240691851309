import React, { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../hooks/redux-hooks"
import { RootState } from "../store"
import Weather from "./weather";
import { getParks } from "../reducers/parks";

const Parks = () => {
    const dispatch = useAppDispatch();
    const parksList = useAppSelector((state: RootState) => state.parks.list)

    useEffect(() => {
        dispatch(getParks());
    }, [dispatch])

    return (
        <div className="parks">
            <div className="parks-grid">
                <div className="parks-grid-item-header"></div>
                <div className="parks-grid-item-header"><h3>Today</h3></div>
                <div className="parks-grid-item-header"><h3>Budget</h3></div>
                <div className="parks-grid-item-header"><h3>Year ago</h3></div>
                <div className="parks-grid-item-header"><h3>Weather</h3></div>
                {
                    parksList.length > 0 && parksList.map(park=>(
                        <>
                            <div className="parks-grid-item"><img src={`assets/${park.code}.png`} alt={park.code} /></div>
                            <div className="parks-grid-item">{park.totalVisitors}</div>
                            <div className="parks-grid-item" style={{color: park.totalVisitors < park.budget ? "#FF0000": "#00C400" }}>{park.budget}</div>
                            <div className="parks-grid-item" style={{color: park.totalVisitors < park.visitorsYearAgo ? "#FF0000": "#00C400" }}>{park.visitorsYearAgo}</div>
                            <div className="parks-grid-item"><Weather locationCode={park.code} /></div>
                        </>
                    ))
                }
            </div>
        </div>
    )
}

export default Parks