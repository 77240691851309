//@ts-nocheck
/* eslint-disable */ 
import React, { useEffect } from "react";

const LOCATION_DICTIONARY = {
    MLK: "52d3515d09/kownaty/",
    MLW:  "52d2321d01/warsaw/",
    MLG:  "54d3518d65/gdansk/"
}

const Weather = (props: {locationCode: string}) => {
  const location = LOCATION_DICTIONARY[props.locationCode];

  useEffect(() => {    
    !(function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (!d.getElementById(id)) {
        js = d.createElement(s);
        js.id = id;
        js.src = "https://weatherwidget.io/js/widget.min.js";
        fjs.parentNode.insertBefore(js, fjs);
      }
    })(document, "script", "weatherwidget-io-js");
  }, []);

  return (
    <div className="weather">
      <a
        className="weatherwidget-io"
        href={`https://forecast7.com/en/${location}`}
        data-days="3"
        data-theme="pure"
        data-mode="Current"
      />
    </div>
  );
}

export default Weather;
