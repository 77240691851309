import React from "react"
import { useAppDispatch, useAppSelector } from "../hooks/redux-hooks"
import { logout } from "../reducers/auth";
import { useMatch, useNavigate } from "react-router-dom";

const Header = (props: {title?: string}) => {
    const dispatch = useAppDispatch();
    const authInfo = useAppSelector((state) => state.auth.info); 
    const navigate = useNavigate();   
    const handleLogout = async () => {
        await dispatch(logout()).unwrap();
        window.location.reload();
    }
    const handleAdminClick = () => {
        navigate("/admin")
    }
    const handleHomeClick = () => {
        navigate("/")
        window.location.reload();
    }
    const isHome = useMatch('/')
    const isAddUser = useMatch('/admin/addUser')

    if(!authInfo) {
        return (
            <header className="header">
                Login
            </header>
        )
    }

    return (
        <header className="header">
            <div className="loggedIn">
                <div>{props.title}</div>
                <div className="buttons">
                    {!!(authInfo.is_admin && (isHome||isAddUser)) && <button onClick={handleAdminClick}>Admin</button>}
                    {!isHome && <button onClick={handleHomeClick}>Home</button>}
                    <button onClick={handleLogout}>Logout</button>
                </div>
            </div>
        </header>
    )
}

export default Header