import React from "react";
import { Outlet } from "react-router-dom";
import { useAppSelector } from "../hooks/redux-hooks";
import { Navigate } from "react-router-dom";
import Header from "./header";

const ProtectedLayout = () => {
  const auth = useAppSelector((state) => state.auth.info);

  if (!auth) {
    return <Navigate replace to={"/login"} />;
  }

  return (
    <>
      <Header title="Parks status" />
      <Outlet />
    </>
  );
};

export default ProtectedLayout;