import React, { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../hooks/redux-hooks"
import { RootState } from "../store"
import { deleteUser, getUsers } from "../reducers/users";
import { useNavigate } from "react-router-dom";
import { uploadBudgetFile } from "../reducers/files";

const Admin = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [file, setFile] = useState<File | null>(null);
    const usersList = useAppSelector((state: RootState) => state.users.list)
    const filesError = useAppSelector((state: RootState) => state.files.error)
    const filesStatus = useAppSelector((state: RootState) => state.files.status)
    const handleDeleteClick = async (id: number) => {dispatch(deleteUser({id}));dispatch(getUsers());}
    const handleAddClick = async () => {navigate("/admin/addUser")}
    const handleUploadClick = async () => {dispatch(uploadBudgetFile(file)); setFile(null);}

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        setFile(e.target.files[0]);
      }
    };    
    useEffect(() => {
        dispatch(getUsers());
    }, [dispatch])

    return (
        <div className="admin">
            <div className="users">
                <h2>Users</h2>
                <div className="users-grid">
                    <div className="users-grid-item-header"><h3>Username</h3></div>
                    <div className="users-grid-item-header"><button onClick={handleAddClick}>Add</button></div>
                    {
                        usersList.length > 0 && usersList.map(user=>(
                            <>
                                <div className="users-grid-item">{user.name}</div>
                                <div className="users-grid-item"><button onClick={()=>handleDeleteClick(user.id)}>delete</button></div>
                            </>
                        ))
                    }
                </div>
            </div>
            <div>
                <h2>Stats</h2>
                <div className="budget-download">
                    <a href={`${process.env.REACT_APP_API_URL}/budget`} target="__blank">
                        Get current stats csv
                    </a>
                </div>
                <div className="budget-upload">
                    <label htmlFor="file" className="sr-only">
                        <input id="file" type="file" onChange={handleFileChange} />
                        Upload a new stats csv
                    </label>
                    {file && (
                        <section>
                        File details:
                        <ul>
                            <li>Name: {file.name}</li>
                            <li>Type: {file.type}</li>
                            <li>Size: {file.size} bytes</li>
                        </ul>
                        </section>
                    )}
                    {file && <button onClick={handleUploadClick}>Upload a file</button>}
                    {filesError && <div className="error">
                        <span>Failed to add user</span>
                    </div>}
                    {filesStatus === "uploaded" && <div className="info">
                        <span>File uploaded</span>
                    </div>}
                </div>
            </div>
        </div>
    )
}

export default Admin