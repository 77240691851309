import axiosInstance from "../api/axiosInstance";
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

interface Park {
  code: string,
  totalVisitors: number,
  budget: number,
  visitorsYearAgo: number,
}

interface ParksState {
  list: Park[]
  status: "idle" | "loading" | "failed";
  error: string | null;
}

const initialState: ParksState = {
  list: [],
  status: "idle",
  error: null
}

export const getParks = createAsyncThunk("getParks", async () => {
  try {
    const response = await axiosInstance.get("/parks");
    const resData = response.data;
    
    return resData;
  } catch (error: any) {
    if(error.response.status === 401) {
      localStorage.removeItem("userInfo");
      window.location.replace("/login");
    }
    return []
  }
});

export const parksSlice = createSlice({
  name: 'parks',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getParks.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(
        getParks.fulfilled,
        (state, action: PayloadAction<Park[]>) => {
          state.status = "idle";
          state.list = action.payload;
        }
      )
      .addCase(getParks.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Failed to get parks";
      })
    }
})

export default parksSlice.reducer