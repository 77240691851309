import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axiosInstance from "../api/axiosInstance";
import { UserLogin } from './auth';

type User = {
  id: number;
  name: string;
};

interface UsersState {
  list: User[],
  status: "idle" | "loading" | "failed" | "created";
  error: string | null;
}

const initialState: UsersState = {
    list: [],
    status: "idle",
    error: null
}

export const getUsers = createAsyncThunk("getUsers", async () => {
  const response = await axiosInstance.get("/users");    
  const resData = response.data;

  return resData;
});

export const addUser = createAsyncThunk("addUser", async (data: UserLogin) => {
  const response = await axiosInstance.put("/users", data);    
  const resData = response.data;

  return resData;
});

export const deleteUser = createAsyncThunk("deleteUser", async (data: {id: number}) => {
  const response = await axiosInstance.delete(`/users/${data.id}`);    
  const resData = response.data;

  return resData;
});

export const usersSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(
        getUsers.fulfilled,
        (state, action: PayloadAction<User[]>) => {
          state.status = "idle";
          state.list = action.payload;
        }
      )
      .addCase(getUsers.rejected, (state, action) => {
        state.status = "failed";
        state.list = [];
        state.error = "Wrong username or password";
      })
      .addCase(addUser.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(
        addUser.fulfilled,
        (state) => {
          state.status = "created";
        }
      )
      .addCase(addUser.rejected, (state, action) => {
        state.status = "failed";
        state.list = [];
        state.error = "Failed to add user";
      })
      .addCase(deleteUser.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(
        deleteUser.fulfilled,
        (state) => {
          state.status = "idle";
        }
      )
      .addCase(deleteUser.rejected, (state, action) => {
        state.status = "failed";
        state.list = [];
        state.error = "Failed to delete user";
      })
    }
})

export default usersSlice.reducer